* {
  box-sizing: border-box;
}

.app {
  max-width: 1440px;
  margin: auto;
  color: #1a1b22;
}

.hover-fade {
  transition: opacity 0.3s ease;
}

.hover-fade:hover {
  opacity: 0.6;
}
