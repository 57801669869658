.results {
  padding: 32px 16px 24px;
  background-color: rgb(245, 246, 247);
}

.results__title {
  font-family: Roboto-slab, sans-serif;
  font-weight: 400;
  font-size: 30px;
  line-height: 34px;
  margin: 0 0 58px 0;
}

.results__article-container {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

@media screen and (min-width: 650px) {
  .results__article-container {
    display: grid;
    grid-template-columns: repeat(3, minmax(150px, 1fr));
    column-gap: 8px;
    row-gap: 10px;
  }
  .results {
    padding-top: 40px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 40px;
    padding-left: 40px;
  }
}
@media screen and (min-width: 999px) {
  .results__article-container {
    gap: 16px;
  }

  .results {
    padding-top: 80px;
    padding-right: 104px;
    padding-left: 104px;
  }

  .results__title {
    font-size: 40px;
    line-height: 46px;
  }
}
