.footer {
  display: flex;
  flex-direction: column;
  padding: 20px 16px 22px;
  font-family: Roboto, sans-serif;
  background-color: #fff;
}

.footer__nav-wrapper {
  display: flex;
  justify-content: space-between;
  height: 74px;
  flex-basis: content;
}

.footer__social-links {
  padding: 0;
  margin: 0;
  list-style: none;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 26px;
  place-items: center;
}

.footer__social-link-image {
  display: block;
  width: 20px;
}

.footer__page-links {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 28px;
  font-size: 18px;
  font-weight: 400;
  height: 100%;
}

.footer__page-link {
  text-decoration: none;
  color: inherit;
}

.footer__page-link a {
  text-decoration: none;
  color: inherit;
}

.footer__copyright {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #b6bcbf;
  margin: 38px 0 22px;
  text-align: center;
}

@media screen and (min-width: 470px) {
  .footer__social-links {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    align-items: end;
  }

  .footer__page-links {
    flex-direction: row;
    gap: 18px;
  }

  .footer__copyright {
    font-size: 16px;
  }
}

@media screen and (min-width: 767px) {
  .footer {
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-end;
    padding: 24px 40px 18px;
  }

  .footer__nav-wrapper {
    height: auto;
  }

  .footer__page-links {
    align-items: flex-end;
    margin-right: 25px;
    line-height: 24px;
  }

  .footer__copyright {
    margin: 0;
    font-size: 16px;
  }

  .footer__social-links {
    gap: 18px;
  }
}

@media screen and (min-width: 999px) {
  .footer {
    padding: 32px 104px 26px;
  }

  .footer__page-links {
    margin-right: 41px;
    gap: 28px;
  }

  .footer__social-links {
    gap: 28px;
  }
}
