.logo-text {
  font-family: Roboto-slab, sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}

.logo-text_light {
  color: #ffffff;
}
