.preloader_container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 282px;
  background-color: #f5f6f7;
}

.preloader__circle {
  display: block;
  width: 74px;
  height: 74px;
  border: 4px solid #444;
  border-bottom-color: #888;
  border-radius: 50%;
  animation: spin 0.75s infinite linear;
}

.preloader__text {
  top: 98px;
  text-align: center;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #b6bcbf;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
