.button {
  all: unset;
  text-align: center;
}

.popup__close-button {
  background-image: url(../../images/close.svg);
  background-size: cover;
  background-color: transparent;
  background-blend-mode: overlay;
  width: 24px;
  height: 24px;
  position: absolute;
  top: -40px;
  right: 16px;
  border-style: hidden;
  transition: opacity 0.4s;
  cursor: pointer;
  padding: 0;
}

.form {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
}

.popup {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(0 0 0 / 50%);
  z-index: 2;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  opacity: 0;
}

.popup_active {
  visibility: visible;
  opacity: 1;
}

.popup__window {
  padding: 16px 17px;
  background-color: #fff;
  width: 100%;
  border-radius: 16px;
  font-family: Roboto, sans-serif;
  color: #1a1b22;
  position: relative;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
}

.popup__title {
  padding: 0;
  margin: 0 0 18px 0;
  font-size: 24px;
  font-weight: 900;
  line-height: 28px;
}

.form__redirect-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  height: 20px;
}

.form__redirect-button {
  border: none;
  background: none;
  margin: 0;
  color: #2f71e5;
  cursor: pointer;
}

@media screen and (min-width: 450px) {
  .popup__window {
    max-width: 430px;
  }
}

@media screen and (min-width: 650px) {
  .form__submit-button {
    margin-top: 39px;
  }
  .popup__title {
    margin-bottom: 22px;
  }
  .popup__close-button {
    width: 40px;
    height: 40px;
    top: -46px;
    right: -46px;
  }
  .popup__window {
    padding: 34px 36px 28px;
  }
}
