.news-card {
  width: 100%;
  height: 100%;
  position: relative;
  min-height: 440px;
  background-color: #fff;
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.news-card__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* aspect-ratio: 1.5 / 1; */
}

.news-card__image-container {
  height: 196px;
}

.news-card__info {
  padding: 0 16px;
}

.news-card__date {
  padding: 0;
  display: block;
  margin-top: 16px;
  margin-bottom: 10px;
  color: #b6bcbf;
  font-family: Source sans pro, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

.news-card__header {
  font-family: Roboto-slab, sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 24px;
  margin: 0 0 14px 0;
  padding: 0;

  /* Hides content past 2 lines of text */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.news-card__text {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  min-height: 110px;
  margin: 0 0 8px 0;
  padding: 0;

  /* Hides content past 5 lines of text */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  -webkit-box-orient: vertical;
}

.news-card__ref {
  width: fit-content;
  font-family: Roboto-slab, sans-serif;
  text-transform: uppercase;
  color: #b6bcbf;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.4px;
  padding: 0 16px 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  cursor: pointer;
}

@media screen and (min-width: 650px) {
  .news-card {
    min-height: 420px;
  }

  .news-card__header {
    margin-bottom: 10px;
    min-height: 72px;

    /* Hides content past 3 lines of text */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .news-card__text {
    margin-bottom: 12px;
    min-height: 88px;
    /* Hides content past 4 lines of text */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  .news-card__date {
    margin-bottom: 12px;
  }

  .news-card__ref {
    padding-bottom: 24px;
    padding-left: 24px;
  }

  .news-card__info {
    padding-left: 24px;
    padding-right: 24px;
  }

  .news-card__image-container {
    height: 150px;
  }
}

@media screen and (min-width: 999px) {
  .news-card__header {
    font-size: 26px;
    line-height: 30px;
    min-height: 60px;
  }

  .news-card {
    min-height: 576px;
  }

  .news-card__date {
    margin-top: 20px;
  }

  .news-card__text {
    margin-bottom: 18px;
    min-height: 110px;
    /* Hides content past 5 lines of text */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    line-clamp: 5;
    -webkit-box-orient: vertical;
  }

  .news-card__header {
    margin-bottom: 16px;
    /* Hides content past 2 lines of text */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .news-card__image-container {
    height: 272px;
  }
}
