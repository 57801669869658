.show-more {
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  border-radius: 80px;
  background-color: #fff;
  cursor: pointer;
  width: 100%;
  height: 56px;
  padding: 0;
  margin: 16px auto 0;
  transition: background-color 0.3s ease-in-out;

  color: #1a1b22;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}

.show-more:hover {
  background-color: #e8e8e8;
  opacity: 0.9;
}

@media screen and (min-width: 650px) {
  .show-more {
    max-width: 240px;
    margin: 32px auto 16px;
  }
}
@media screen and (min-width: 999px) {
  .show-more {
    max-width: 288px;
    margin: 64px auto 56px;
  }
}
