.hamburger {
  all: unset;
  cursor: pointer;
  width: 24px;
  height: 24px;
  background-size: contain;
  background-image: url('../../images/burger.svg');
}

.cross-button {
  all: unset;
  cursor: pointer;
  width: 24px;
  height: 24px;
  background-size: contain;
  background-image: url('../../images/close.svg');
}

.cross-button_dark {
  background-image: url('../../images/close-black.svg');
}

.hamburger_dark {
  background-image: url('../../images/burger-black.svg');
}

.cross-button_light {
  background-image: url('../../images/close.svg');
}

.hamburger_light {
  background-image: url('../../images/burger.svg');
}
